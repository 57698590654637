
export const ObtenerApiKeyRichText = ( env ) => {    

    const fechaDay = new Date( Date.now() ).getDate();

    const arrayApikey1 = [1, 2, 3];
    const arrayApikey2 = [4, 5];    
    const arrayApikey3 = [6, 7, 8];
    const arrayApikey4 = [9, 10];
    const arrayApikey5 = [11, 12, 13];
    const arrayApikey6 = [14, 15];
    const arrayApikey7 = [16, 17];
    const arrayApikey8 = [18, 19, 20];
    const arrayApikey9 = [21, 22];

    const arrayApikey10 = [23, 24];
    const arrayApikey11 = [25, 26, 27];
    const arrayApikey12 = [28, 29];
    const arrayApikey13 = [30, 31];
    
    if( arrayApikey1.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_MIG ?? '';
    }
    else if( arrayApikey2.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_MAU ?? '';
    }
    else if( arrayApikey3.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_YAJA ?? '';
    }
    else if( arrayApikey4.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT ?? '';
    }

    else if( arrayApikey5.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_IVAN ?? '';
    }
    else if( arrayApikey6.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_RODOLFO ?? '';
    }
    else if( arrayApikey7.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_ALEX ?? '';
    }
    else if( arrayApikey8.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_MAUR_2 ?? '';
    }
   
    else if( arrayApikey9.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_LUIS ?? '';
    }
    else if( arrayApikey10.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_CARLOS ?? '';
    }
    else if( arrayApikey11.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_DIANA ?? '';
    }
    else if( arrayApikey12.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_LUCIA ?? '';
    }
    else if( arrayApikey13.indexOf( fechaDay ) !== -1 ){
        return env.REACT_APP_API_KEY_RICHTEXT_RICARDO ?? '';
    }    
    
}
